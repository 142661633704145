<template>
  <svg
    width="8"
    height="11"
    viewBox="0 0 8 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.960938 6.25C0.445312 6.25 0.1875 6.85938 0.5625 7.21094L3.35156 10C3.5625 10.2344 3.91406 10.2344 4.14844 10L6.9375 7.21094C7.28906 6.85938 7.03125 6.25 6.53906 6.25H0.960938ZM6.9375 3.78906L4.14844 1C3.91406 0.789062 3.5625 0.789062 3.35156 1L0.5625 3.78906C0.1875 4.16406 0.445312 4.75 0.960938 4.75H6.53906C7.03125 4.75 7.28906 4.16406 6.9375 3.78906Z"
      fill="currentColor"
    />
  </svg>
</template>
